import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PropertiesPlugin from "./plugins/PropertiesPlugin";
import TimePlugin from "./plugins/TimePlugin";
import { VueReCaptcha } from 'vue-recaptcha-v3'

// axios.defaults.baseURL = 'https://nestor.com.tw'
axios.defaults.baseURL = 'https://127.0.0.1:8000'
createApp(App).use(store).use(router).use(PropertiesPlugin).use(TimePlugin).use(VueReCaptcha, { siteKey: '6LdaQikqAAAAAF0PW3gzzWD2h6EE6aKo7ExunTbP' }).mount('#app')
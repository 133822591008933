import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import createStore from '../store/index.js'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login/',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/404/',
        name: '404',
        component: () => import('../views/Page404View'),
        hidden: true
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404/',
        hidden: true
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!createStore.getters.isLoggedIn) {
            next({ name: 'login' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router

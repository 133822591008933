<template>
    <section class="home_section">
    </section>
</template>

<style lang="scss">
.home_section {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: flex;
    flex-wrap: wrap;
}

</style>

<script>

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>
